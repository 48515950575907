<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Detail Project</h4>
          </div>

          <!-- <button class="btn-add" @click="generatePdf">Export</button>
            <button
              class="btn-add"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              + Tambah
            </button> -->
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center justify-content-end">
            <button
              class="btn btn-save"
              :style="{ minWidth: '170px' }"
              @click="this.showModal = true"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5082 2.125C12.3311 2.125 12.1469 2.19583 12.0124 2.33042L10.7161 3.62667L13.3724 6.28292L14.6686 4.98667C14.9449 4.71042 14.9449 4.26417 14.6686 3.98792L13.0111 2.33042C12.8694 2.18875 12.6924 2.125 12.5082 2.125ZM9.95819 6.38917L10.6099 7.04083L4.19236 13.4583H3.54069V12.8067L9.95819 6.38917ZM2.12402 12.2188L9.95819 4.38458L12.6144 7.04083L4.78027 14.875H2.12402V12.2188Z"
                  fill="#fff"
                />
              </svg>
              Ubah Parameter
            </button>
          </div>

          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div
            v-else
            :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }"
          >
            <loading
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }"
              :active="isLoading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              color="#68caf8"
              height="80"
              width="80"
            ></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
  <modalProject
    :onPage="true"
    :show="showModal"
    :type="'detail_project'"
    @hide="setHide()"
    @update="updateData($event)"
    :parameter="parameterReport"
    :project="optionProject"
  ></modalProject>
</template>
  
  <script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import modalProject from "../../components/report/modalProject.vue";
// import Select2 from "vue3-select2-component";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../../../../assets/font/poppins-bold.js";
import "../../../../assets/font/poppins-regular.js";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Loading from "vue3-loading-overlay";

import $ from "jquery";

import moment from "moment";
import "moment/locale/id";

import "@/assets/fonts/poppins/poppins.normal";
import "@/assets/fonts/poppins/poppins.italic";
import "@/assets/fonts/poppins/poppins.bold-italic";
import "@/assets/fonts/poppins/poppins.bold";
import { cksClient } from "../../../../helper";
//   import { get_ReportDebt } from "../../../../actions/report/summary";
import { DATA } from "../../../../constans/Url";
import { get_ProjectList } from "../../../../actions/project";
import { get_ReportDetailProject } from '../../../../actions/report/summary';
// import { NONE } from "apisauce";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    // Select2,
    Loading,
    modalProject,
  },
  data() {
    return {
      showModal: false,
      fullPage: true,
      isLoading: false,
      canvas: null,
      optionProject: [],
      akunPerkiraan: [],
      dataEstimatedAccount: [],
      parameterReport: {
        kode_project: this.$route.query.kode_project,
      },
      dataCompany: {
        nama: "",
        divisi: "",
        alamat:"",
        email: "",
        no_telp: "",
        logo: "",
      },

      dataHeaderRab: [],
      dataReportRab: [],
      dataHeaderPE: [],
      dataReportPE: [],
      dataHeaderInvoice: [],
      dataReportInvoice: [],
      dataNilai: {
        total_pembayaran: "Rp. 0",
        nilai_kontrak: "Rp. 0",
        fee_marketing: "Rp. 0",
        total_pe: "Rp. 0",
        total_rab: "Rp. 0",
        total_pengeluaran: "Rp. 0",
        laba_rugi: "Rp. 0",
      },
      dataClient: {
        nama: "",
        no_telp: "",
        email: "",
        provinsi: "",
      },
      titleDate: "",
      titleProject: "",
      titleReport: "Detail Project",
    };
  },

  mounted() {
    this.getProject();
    this.getData();
    // console.log(cksClient().get("_account"));
    // this.tooltip();
    // this.generatePdf();
  },

  methods: {
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    updateData(event) {
      this.parameterReport = event;
      this.getData();
    },

    getProject() {
      get_ProjectList(
        {
          id_company: cksClient().get("_account").id_company,
          search: "",
          id_divisi: "",
        },
        (res) => {
          this.optionProject = [];
          for (const key in res.list) {
            if (Object.hasOwnProperty.call(res.list, key)) {
              const element = res.list[key];
              this.optionProject.push({
                id: element.kode,
                text: element.nama,
              });
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },

    setHide() {
      this.showModal = false;
    },

    getData() {
      var data = {
        ...this.parameterReport,
      };
      data.id_company = cksClient().get("_account").id_company;
      this.isLoading = true;
      get_ReportDetailProject(
        data,
        (res) => {
          const {
            nilai, 
            client,
            header_rab,
            report_rab,
            header_pe,
            report_pe,
            header_invoice,
            report_invoice,
            project,
            company,
          } = res;
          this.dataCompany = company;
          this.dataClient = client;
          this.dataHeaderRab = header_rab;

          for (const key in report_rab) {
            if (Object.hasOwnProperty.call(report_rab, key)) {
              const element = report_rab[key];
              report_rab[key].nilai_rab = this.formatMoney(element.nilai_rab);
              report_rab[key].nilai_pengeluaran = this.formatMoney(element.nilai_pengeluaran);
              if (element.hasChild == 1 && element.sub_level == "0") {
              report_rab[key].is_bold = true;
              }
            }
          }

          this.dataReportRab = report_rab;
          this.dataHeaderPE = header_pe

          for (const key in report_pe) {
            if (Object.hasOwnProperty.call(report_pe, key)) {
              const element = report_pe[key];
              report_pe[key].nilai = this.formatMoney(element.nilai);
              if (element.hasChild == 1 && element.sub_level == "0") {
                report_pe[key].is_bold = true;
              }
            }
          }

          this.dataReportPE = report_pe

          this.dataHeaderInvoice = header_invoice;
          this.dataReportInvoice = report_invoice;

          this.titleReport = project.nama;
          this.titleProject = project.lokasi;
          this.convertDate(project.tanggal_mulai, project.tanggal_selesai);
          // this.titleDate = moment(project.tanggal_pelaksanaan).format("DD MMMM YYYY")

          this.dataNilai.total_pembayaran = `Rp. ${this.formatMoney(
            nilai.total_pembayaran
          )}`;

          this.dataNilai.nilai_kontrak = `Rp. ${this.formatMoney(
            nilai.nilai_kontrak
          )}`;

          this.dataNilai.total_rab = `Rp. ${this.formatMoney(
            nilai.total_rab
          )}`;

          this.dataNilai.total_pe = `Rp. ${this.formatMoney(
            nilai.total_pe
          )}`;

          this.dataNilai.total_pengeluaran = `Rp. ${this.formatMoney(
            nilai.total_pengeluaran
          )}`;

          this.dataNilai.laba_rugi = `Rp. ${this.formatMoney(
            nilai.laba_rugi
          )}`;
          this.generatePdf();
        },
        (err) => {
          console.log(err);
        }
      );
    },

    convertDate(date_start, date_end) {
      var start = moment(date_start);
      var end = moment(date_end);
      if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
        this.titleDate = `Tgl. ${start.format("DD")} s/d ${end.format(
          "DD MMM YYYY"
        )}`;
      } else if (
        start.format("YYYY") == end.format("YYYY") &&
        start.isValid()
      ) {
        this.titleDate = `Tgl. ${start.format("DD MMM")} s/d ${end.format(
          "DD MMM YYYY"
        )}`;
      } else {
        this.titleDate = `Tgl. ${start.format(
          "DD MMM YYYY"
        )} s/d ${end.format("DD MMM YYYY")}`;
      }
    },

    convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#fff"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoThreeArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 3);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const thirdArray = wordsArray.slice(arraySize * 2);

      return [firstArray, secondArray, thirdArray];
    },

    async generatePdf() {
      this.isLoading = false;
      const doc = new jsPDF("l", "mm", "a4");
      //   var header = "img/bg-header.png";
      var logo = await this.convertImgToBase64(
        DATA.COMPANY(this.dataCompany.logo)
      );
      doc.addImage(logo, "png", 7, 20, 16, 16, null, null, 0);
            
      doc.setFont("Poppins-Bold").setFontSize(12);
      doc.setTextColor(14, 44, 78);
      doc.text(26, 28, `${this.dataCompany.nama}`, {
        align: "left",
      });
      doc.setFont("Poppins-Regular").setFontSize(10);
      doc.setTextColor(137, 137, 137);
      doc.text(26, 34, `${this.dataCompany.email}`, {
        align: "left",
      });

      doc.setFont("Poppins-Regular").setFontSize(10);
      doc.setTextColor(137, 137, 137);
      const [array1, array2, array3] = this.splitTextIntoThreeArrays(
        this.dataCompany.alamat
      );

      doc.text(288, 22, array1.join(" "), {
        align: "right",
      });
      doc.text(288, 28, array2.join(" "), {
        align: "right",
      });
      doc.text(288, 34, array3.join(" "), {
        align: "right",
      });

      var bg = new Image();
      bg.src = "img/bg-blue.png";
      doc.addImage(bg, "png", 288, 10, 278, 2500 / 72, null, null, 180);

      doc.setTextColor(255, 255, 255);
      doc.setFontSize(20);
      doc.setFont("Poppins-Bold");
      doc.text(17, 57, this.titleReport, {
        align: "left",
      });

      doc.setProperties({
        title: "Detail Project",
      });

      doc.setFontSize(12);
      doc.setFont("Poppins-Regular");
      doc.text(17, 64, this.titleProject, {
        align: "left",
      });

      doc.setFontSize(10);
      doc.text(17, 70, this.titleDate, {
        align: "left",
      });

      doc.setFontSize(12);
      doc.setFont("Poppins-Bold");
      doc.text(279, 56, this.dataClient.nama, {
        align: "right",
      });

      doc.setFontSize(10);
      doc.setFont("Poppins-Regular");
      doc.text(
        279,
        63,
        `${this.dataClient.email} / ${this.dataClient.no_telp}`,
        {
          align: "right",
        }
      );

      doc.setFontSize(10);
      doc.setFont("Poppins-Regular");
      doc.text(279, 70, `${this.dataClient.provinsi}, Indonesia`, {
        align: "right",
      });

      let wantedTableWidth = 240;
      let pageWidth = doc.internal.pageSize.width;
      console.log(pageWidth)
      let aa = (pageWidth - wantedTableWidth) / 2;
      var tableDataSummary = {
        columns: [
          {
            title: "",
            dataKey: "nilai_kontrak",
          },
          {
            title: "",
            dataKey: "total_pembayaran",
          }, // {
          //   title: "",
          //   dataKey: "fee_marketing",
          // },
          {
            title: "",
            dataKey: "total_pe",
          },
          {
            title: "",
            dataKey: "total_rab",
          },
          {
            title: "",
            dataKey: "total_pengeluaran",
          },
          {
            title: "",
            dataKey: "laba_rugi",
          },
        ],
        rows: [this.dataNilai],
      };

      doc.autoTable({
        columns: tableDataSummary.columns,
        body: tableDataSummary.rows,
        showHead: "everyPage",

        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#324C8F";
            if (data.column.dataKey === "nama") {
              data.cell.styles.halign = "left";
            } else {
              data.cell.styles.halign = "right";
            }
          }
          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }

          // console.log(tableData.rows);
        },

        columnStyles: {
          0: {
            cellWidth: 40,
            halign: "center",
          },
          1: {
            cellWidth: 40,
            halign: "center",
          },
          2: {
            cellWidth: 40,
            halign: "center",
          },
          3: {
            cellWidth: 40,
            halign: "center",
          },
          4: {
            cellWidth: 40,
            halign: "center",
          },
          5: {
            cellWidth: 40,
            halign: "center",
          },
        },
        margin: {
          left: aa,
          right: aa,
          top: 2,
        },
        startY: 85,
        theme: "plain",
      });

      var tableText = {
        columns: [
          {
            title: "",
            dataKey: "text_nilai_kontrak",
          },
          {
            title: "",
            dataKey: "text_total_pembayaran",
          },
          // {
          //   title: "",
          //   dataKey: "text_fee_marketing",
          // },
          {
            title: "",
            dataKey: "text_total_pe",
          },
          {
            title: "",
            dataKey: "text_total_rab",
          },
          {
            title: "",
            dataKey: "text_total_pengeluaran",
          },
          {
            title: "",
            dataKey: "text_laba_rugi",
          },
        ],
        rows: [
          {
            text_total_pembayaran: "Total Pembayaran",
            text_nilai_kontrak: "Nilai Kontrak",
            // text_fee_marketing: "Fee Marketing",
            text_total_pe: "Total PE",
            text_total_rab: "Total RAB",
            text_total_pengeluaran: "Total Pengeluaran",
            text_laba_rugi: "Laba/Rugi",
          },
        ],
      };

      doc.autoTable({
        columns: tableText.columns,
        body: tableText.rows,
        showHead: "everyPage",
        startY: doc.lastAutoTable.finalY - 1, // 10 margin
        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#324C8F";
            if (data.column.dataKey === "nama") {
              data.cell.styles.halign = "center";
            } else {
              data.cell.styles.halign = "center";
            }
          }
          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }

          // console.log(tableData.rows);
        },

        columnStyles: {
          0: {
            cellWidth: 40,
            halign: "center",
            textColor: "#898989",
          },
          1: {
            cellWidth: 40,
            halign: "center",
            textColor: "#898989",
          },
          2: {
            cellWidth: 40,
            halign: "center",
            textColor: "#898989",
          },
          3: {
            cellWidth: 40,
            halign: "center",
            textColor: "#898989",
          },
          4: {
            cellWidth: 40,
            halign: "center",
            textColor: "#898989",
          },
          5: {
            cellWidth: 40,
            halign: "center",
            textColor: "#898989",
          },
        },
        margin: {
          left: aa,
          right: aa,
        },
        theme: "plain",
      });

      var positionY = doc.previousAutoTable.finalY + 10;

      doc.setTextColor(18, 17, 17);
      doc.setFontSize(12);
      doc.setFont("Poppins-Regular");
      doc.text(11.5, positionY, `1. Item PE`, {
        align: "left",
      }); 

      positionY += 5;

      var tablePE = {
        columns: this.dataHeaderPE,
        rows: this.dataReportPE,
      };
      doc.autoTable({
        columns: tablePE.columns,
        body: tablePE.rows,
        showHead: "everyPage",
        startY: positionY,
        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#FFF";
            data.cell.styles.fillColor = "#6BACCC";
            if (data.column.dataKey === "nama") {
              data.cell.styles.halign = "left";
            } else {
              data.cell.styles.halign = "right";
            }
          }

          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }
          if (data.column.index == 0 && data.row.raw.hasChild == 1) {
            data.row.cells.nama.colSpan = 3;
          }
          if (data.column.index === 0 && data.row.raw.is_bold) {
            data.cell.styles.fontStyle = "bold";
          }

          // console.log(tableData.rows);
        },

        didDrawCell: function (data) {
          if (data.row.raw.status == 'tidak') {
            doc.setDrawColor(209, 19, 19);
            doc.setLineWidth(0.2); // Border width
            if (data.column.index == 0 && data.row.raw.nama) {
              var padding = parseInt(data.row.raw.sub_level)
                ? parseInt(data.row.raw.sub_level) + 1
                : 1;
              var xPos = data.cell.x + 3 * padding;
              var yPos = data.cell.y + data.row.height / 2;
              doc.line(
                xPos,
                yPos,
                xPos +
                  (doc.getStringUnitWidth(data.row.raw.nama) * 11) /
                    doc.internal.scaleFactor,
                yPos
              );
            } else if (data.column.index == 1 && data.row.raw.nilai_rab) {
              var textWidth1 =
                (doc.getStringUnitWidth(data.row.raw.nilai_rab) * 11) /
                doc.internal.scaleFactor;
              var xPos1 = data.cell.x + data.cell.width - textWidth1;
              var yPos1 = data.cell.y + data.row.height / 2;
              doc.line(
                xPos1,
                yPos1,
                xPos1 +
                  (doc.getStringUnitWidth(data.row.raw.nilai_rab) * 11) /
                    doc.internal.scaleFactor -
                  2,
                yPos1
              );
            } else if (
              data.column.index == 2 &&
              data.row.raw.nilai_pengeluaran
            ) {
              var textWidth2 =
                (doc.getStringUnitWidth(data.row.raw.nilai_pengeluaran) * 11) /
                doc.internal.scaleFactor;
              var xPos2 = data.cell.x + data.cell.width - textWidth2;
              var yPos2 = data.cell.y + data.row.height / 2;
              doc.line(
                xPos2,
                yPos2,
                xPos2 +
                  (doc.getStringUnitWidth(data.row.raw.nilai_pengeluaran) *
                    11) /
                    doc.internal.scaleFactor -
                  2,
                yPos2
              );
            }
            // var xPos = data.cell.x;
            // var yPos = data.cell.y + (data.row.height/2);
            // doc.setDrawColor(209, 19, 19);
            // doc.setLineWidth(0.2); // Border width
            // doc.line(xPos, yPos, xPos + data.cell.width, yPos);
          }
        },
        columnStyles: {
          0: {
            width: 100,
          },
          1: {
            halign: "right",
            width: 60,
          }
        },
        margin: {
          bottom: 30,
        },
        tableLineWidth: 0, // Adjust the border width as needed
        tableLineColor: [0, 0, 0],
        bodyStyles: {
          lineWidth: 0.1,
          lineColor: [199, 199, 201],
        },
        theme: "plain",
      });

      positionY = doc.previousAutoTable.finalY + 10;

      doc.setTextColor(18, 17, 17);
      doc.setFontSize(12);
      doc.setFont("Poppins-Regular");
      doc.text(11.5, positionY, `2. Pengeluaran Item RAB`, {
        align: "left",
      });

      doc.setFont("Poppins-Regular");

      positionY += 5;

      var tableRab = {
        columns: this.dataHeaderRab,
        rows: this.dataReportRab,
      };
      doc.autoTable({
        columns: tableRab.columns,
        body: tableRab.rows,
        showHead: "everyPage",
        startY: positionY,
        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#FFF";
            data.cell.styles.fillColor = "#6BACCC";
            if (data.column.dataKey === "nama") {
              data.cell.styles.halign = "left";
            } else {
              data.cell.styles.halign = "right";
            }
          }

          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }
          if (data.column.index == 0 && data.row.raw.hasChild == 1) {
            data.row.cells.nama.colSpan = 3;
          }
          if (data.column.index === 0 && data.row.raw.is_bold) {
            data.cell.styles.fontStyle = "bold";
          }

          // console.log(tableData.rows);
        },

        didDrawCell: function (data) {
          if (data.row.raw.status == 'tidak') {
            doc.setDrawColor(209, 19, 19);
            doc.setLineWidth(0.2); // Border width
            if (data.column.index == 0 && data.row.raw.nama) {
              var padding = parseInt(data.row.raw.sub_level)
                ? parseInt(data.row.raw.sub_level) + 1
                : 1;
              var xPos = data.cell.x + 3 * padding;
              var yPos = data.cell.y + data.row.height / 2;
              doc.line(
                xPos,
                yPos,
                xPos +
                  (doc.getStringUnitWidth(data.row.raw.nama) * 11) /
                    doc.internal.scaleFactor,
                yPos
              );
            } else if (data.column.index == 1 && data.row.raw.nilai_rab) {
              var textWidth1 =
                (doc.getStringUnitWidth(data.row.raw.nilai_rab) * 11) /
                doc.internal.scaleFactor;
              var xPos1 = data.cell.x + data.cell.width - textWidth1;
              var yPos1 = data.cell.y + data.row.height / 2;
              doc.line(
                xPos1,
                yPos1,
                xPos1 +
                  (doc.getStringUnitWidth(data.row.raw.nilai_rab) * 11) /
                    doc.internal.scaleFactor -
                  2,
                yPos1
              );
            } else if (
              data.column.index == 2 &&
              data.row.raw.nilai_pengeluaran
            ) {
              var textWidth2 =
                (doc.getStringUnitWidth(data.row.raw.nilai_pengeluaran) * 11) /
                doc.internal.scaleFactor;
              var xPos2 = data.cell.x + data.cell.width - textWidth2;
              var yPos2 = data.cell.y + data.row.height / 2;
              doc.line(
                xPos2,
                yPos2,
                xPos2 +
                  (doc.getStringUnitWidth(data.row.raw.nilai_pengeluaran) *
                    11) /
                    doc.internal.scaleFactor -
                  2,
                yPos2
              );
            }
            // var xPos = data.cell.x;
            // var yPos = data.cell.y + (data.row.height/2);
            // doc.setDrawColor(209, 19, 19);
            // doc.setLineWidth(0.2); // Border width
            // doc.line(xPos, yPos, xPos + data.cell.width, yPos);
          }
        },
        columnStyles: {
          0: {
            width: 100,
          },
          1: {
            halign: "right",
            width: 40,
          },
          2: {
            halign: "right",
            width: 40,
          },
        },
        margin: {
          bottom: 30,
        },
        tableLineWidth: 0, // Adjust the border width as needed
        tableLineColor: [0, 0, 0],
        bodyStyles: {
          lineWidth: 0.1,
          lineColor: [199, 199, 201],
        },
        theme: "plain",
      });

      positionY = doc.previousAutoTable.finalY + 8;

      if (positionY >= 170) {
        doc.addPage();
        positionY = 15
      }

      doc.setTextColor(18, 17, 17);
      doc.setFontSize(12);
      doc.setFont("Poppins-Regular");
      doc.text(11.5, positionY, `3. Informasi Pembayaran`, {
        align: "left",
      });

      doc.setFont("Poppins-Regular");

      var dataPembayaran = {
        columns: this.dataHeaderInvoice,
        rows: this.dataReportInvoice,
      };

      doc.autoTable({
        columns: dataPembayaran.columns,
        body: dataPembayaran.rows,
        showHead: "everyPage",
        startY: positionY + 5, // 10 margin
        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#FFF";
            data.cell.styles.fillColor = "#6BACCC";
            if (
              ["id", "tanggal", "jatuh_tempo", "tanggal_bayar"].includes(
                data.column.dataKey
              )
            ) {
              data.cell.styles.halign = "left";
            } else {
              data.cell.styles.halign = "right";
            }
          }

          if (data.row.section === "body" && data.row.raw.status_pembayaran != "paid") {
            data.cell.styles.textColor = "#d11313";
          }

          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }

          // console.log(tableData.rows);
        },

        didDrawCell: function (data) {
          if (data.row.raw.is_border && data.row.raw.is_border == 1) {
            // doc.setDrawColor(255, 0, 0);
            // data.cell.styles.lineColor = '#000'
            // data.cell.styles.lineWidth = 0.2
            // console.log(data.row.height)
            // var cellHeight = data.row.height - doc.internal.getLineHeight();
            var xPos = data.cell.x;
            var yPos = data.cell.y + data.row.height + 0.2;
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.2); // Border width
            doc.line(xPos, yPos, xPos + data.cell.width, yPos);
          }

          // console.log(tableData.rows);
        },

        columnStyles: {
          0: {
            width: 45,
          },
          1: {
            halign: "left",
          },
          2: {
            halign: "left",
          },
          3: {
            halign: "right",
          },
          4: {
            halign: "right",
          },
          5: {
            halign: "left",
          },
          6: {
            halign: "right",
          },
        },
        margin: {
          bottom: 30,
        },
        tableLineWidth: 0, // Adjust the border width as needed
        tableLineColor: [0, 0, 0],
        bodyStyles: {
          lineWidth: 0.1,
          lineColor: [199, 199, 201],
        },
        theme: "plain",
      });

      // PAGE NUMBERING
      // Add Page number at bottom-right
      // Get the number of pages
      const pageCount = doc.internal.getNumberOfPages();
      // var width = doc.internal.pageSize.getWidth();
      // var height = doc.internal.pageSize.getHeight();

      // For each page, print the page number and the total pages
      var img = new Image();
      img.src = "img/line.png";
      for (var i = 1; i <= pageCount; i++) {
        doc.setTextColor(255, 255, 255);
        // Go to page i
        doc.setPage(i);
        doc.addImage(img, "png", 288, 183, 278, 1 / 72, null, null, 180);
        doc.setFontSize(9);
        doc.setTextColor(0, 0, 0);
        doc.text(105, 257, "Finanta Accounting System Report", "center");

        doc.text(
          "Page " + String(i) + " of " + String(pageCount),
          285,
          210 - 20,
          null,
          null,
          "right"
        );

        doc.text(
          `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
          205 - 192,
          210 - 20,
          null,
          null,
          "left"
        );
      }

      setTimeout(() => {
        var iframe = document.getElementById("pdfFrame");
        iframe.src = doc.output("bloburi", {
          filename: "Detail Project.pdf",
        });
      }, 500);
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
  },
};
</script>
  
  <style scoped>
.card-table {
  margin-top: 0px !important;
}

.btn-gaji {
  background-color: #68caf8;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-bonus {
  background-color: #f8ce3a;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-thr {
  background-color: #ff5252;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.dropdown-menu {
  background: transparent !important;
  box-shadow: none !important;
  width: 102px !important;
  min-width: unset;
}

.dropdown-item:nth-child(2) {
  margin-top: 6px;
}

.dropdown-item:nth-child(3) {
  margin-top: 6px;
}

iframe {
  z-index: 1000;
  width: 100%;
  height: 800px;
}
</style>
  
  <style scoped src="../../../../assets/css/style.css"></style>
  
  <style scoped src="../../../../assets/css/custom.css"></style>