<template>
  <div>
    <div
      class="modal fade"
      :id="idModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="card-modal">
            <div class="modal-header">
              <h5 class="modal-title">Parameter Laporan</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <div class="d-flex justify-content-between align-items-center">
                  <div class="modal-sub-title">Parameter</div>
                </div>
                <hr /> -->

              <div class="form-group">
                <label>Project </label>
                <Select2
                  class=""
                  style="width: 100%"
                  v-model="formModal.kode_project"
                  :options="optionProject"
                  placeholder="Pilih Project"
                  @change="mySelectEvent()"
                  @select="mySelectEvent()"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-save"
                style="width: unset"
                @click="showReport"
              >
                Tampilkan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import $ from "jquery";
import { v4 as uuid4 } from "uuid";
import { cksClient } from "../../../../helper";
// import Datepicker from "vue3-date-time-picker";
import Select2 from "vue3-select2-component";
import moment from "moment";

export default {
  name: "modalProject",
  props: {
    show: Boolean,
    label: String,
    type: String,
    onPage: Boolean,
    parameter: Object,
    project: Object,
  },

  components: {
    // Datepicker,
    Select2,
  },

  data() {
    return {
      idModal: uuid4(),
      optionDepartement: this.$props.departement,
      optionProject: this.$props.project,
      accessDepartement: cksClient().get("_account").akses_departement,
      formModal: {
        kode_project:
          this.$props.parameter && this.$props.parameter.kode_project
            ? this.$props.parameter.kode_project
            : "",
      },
    };
  },

  methods: {
    showModal() {
      // this.formModal.date_start = "";
      // this.date_end = "";
      $("#" + this.idModal).modal("show");
      $("#" + this.idModal).on("hidden.bs.modal", () => {
        this.$emit("hide", false);
      });
    },
    format(date, format = "MMMM YYYY") {
      if (date && date.year && parseInt(date.month) >= 0) {
        var string = date.year + "-" + (date.month + 1) + "-01";
        return `${moment(string).format(format)}`;
      }
    },
    dateReport(data, key) {
      this.formModal[key] = data;
    },
    setHide() {
      this.showModalRange = false;
    },
    showReport() {
      if (this.formModal.kode_project) {
        $("#" + this.idModal).modal("hide");
        if (!this.$props.onPage) {
          var type = this.$props.type;
          var data = { ...this.formModal };
          if (type == "detail_project") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "ReportDetailProject",
              query: data,
            });
          }
        } else {
          this.$emit("update", this.formModal);
        }
      }
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    mySelectEvent(val) {
      console.log(val);
    },
  },

  watch: {
    show: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.showModal();
        }, 500);
      }
    },
    project: function (newVal) {
      this.optionProject = newVal;
    },
    parameter: function (newVal) {
      this.formModal.kode_project = newVal.kode_project
        ? newVal.kode_project
        : "";
    },
  },
};
</script>
  
  <style scoped src="../../../../assets/css/style.css"></style>
  
  <style scoped src="../../../../assets/css/custom.css"></style>
  
  <style scoped>
.text :nth-child(1) {
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

#modalProfitLoss .modal-dialog {
  max-width: 700px;
}

#modalBalance .modal-dialog {
  max-width: 633px;
}

.text {
  margin-left: 10px;
}

.text :nth-child(2) {
  font-size: 10px;
  color: #898989;
}

.modal-sub-title {
  font-size: 15px;
  font-weight: 600;
}

.form-group .control-label,
.form-group > label {
  font-weight: 500;
}

.checkbox-text {
  font-weight: 500;
  font-size: 13px;
}
</style>
  